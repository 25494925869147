import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import {
	NotFoundTrigger,
	PageError,
	PageNotFound,
	ScrollToTop,
} from '@automation-analytics/component-library';

import { paths } from 'enums';
import GlobalWrapper from 'components/layout/GlobalWrapper';
import ErrorBoundary from 'navigation/ErrorBoundary';
import RequireAuth from 'navigation/RequireAuth';
import AuthTrigger from 'navigation/AuthTrigger';
import { Authorize, Loading, Logout } from 'pages/utilities';
import { UnauthorizedPage, UnauthorizedExternalUserPage } from 'pages/errors';

const AccountPage = lazy(() => import('pages/AccountPage/AccountPage'));
const LandingPage = lazy(() => import('pages/LandingPage'));
const AssessmentPage = lazy(() => import('pages/AssessmentPage'));
const ReportPage = lazy(() => import('pages/ReportPage'));
const Versions = lazy(() => import('pages/utilities/Versions'));

const router = createBrowserRouter([
	{
		path: paths.root,
		element: (
			<GlobalWrapper>
				<ScrollToTop />
				<Suspense fallback={<Loading />}>
					<Outlet />
				</Suspense>
			</GlobalWrapper>
		),
		errorElement: <ErrorBoundary redirectTo={paths.error} />,
		children: [
			// public utilities
			{
				index: true,
				element: <AuthTrigger redirectTo={paths.account} />,
			},
			{
				path: paths.unauthorized,
				element: <UnauthorizedPage redirectTo={paths.account} />,
			},
			{
				path: paths.logout,
				element: <Logout redirectTo={paths.unauthorized} />,
			},
			{
				path: paths.authorize,
				element: <Authorize redirectTo={paths.account} />,
			},
			// previously protected routes
			{
				path: `${paths.landing}/:prefabId`,
				element: <LandingPage />,
			},
			{
				path: paths.assessment,
				element: <AssessmentPage />,
			},
			{
				path: `${paths.report}/:assessmentId`,
				element: <ReportPage />,
			},
			{
				path: paths.version,
				element: <Versions />,
			},
			// private
			{
				element: <RequireAuth redirectTo={paths.unauthorized} />,
				children: [
					{
						path: paths.account,
						element: <AccountPage />,
					},
				],
			},

			// errors & fallback
			{
				path: paths.wildcard,
				element: <NotFoundTrigger />,
			},
			{
				path: paths.notFound,
				element: <PageNotFound redirectTo={paths.root} />,
			},
			{
				path: paths.error,
				element: <PageError redirectTo={paths.root} />,
			},
			{
				path: paths.unauthorized,
				element: <UnauthorizedPage redirectTo={paths.account} />,
			},
			{
				path: paths.unauthorizedExternalUser,
				element: (
					<UnauthorizedExternalUserPage redirectTo={paths.account} />
				),
			},
		],
	},
]);

export default function Router() {
	return <RouterProvider router={router} />;
}
