import Endpoints from './Endpoints';
import { auxiliaryClient, client } from './clients';
import type {
	SSOLoginResponse,
	BackendDeploymentDetails,
	User,
	LogoutPayload,
	AccountInfo,
	AssessmentPartialPayload,
	AssessmentTemplate,
	AssessmentUpdateResponse,
	LanguageShort,
	PaginatedAccountPrefab,
	PrefabValidationResponse,
	ReportData,
	SalesforceAccountInfo,
} from 'types';

// api functions (auxiliary)
export const getLogin = () => {
	return auxiliaryClient.get<SSOLoginResponse>(Endpoints.oidcLogin, {
		withCredentials: false,
	});
};

export const postLogout = (slo: boolean = false) => {
	return auxiliaryClient.post<LogoutPayload>(
		Endpoints.oidcLogout(slo ? 'yes' : 'no')
	);
};

export const getUser = () => {
	return client.get<{ user: User }>(Endpoints.user);
};

export const getPrefabs = (page: number, rowsPerPage: number) => {
	return client.get<PaginatedAccountPrefab>(
		Endpoints.getPrefabs(page.toString(), rowsPerPage.toString())
	);
};

export const postPrefab = (payload: AccountInfo) => {
	return client.post(Endpoints.postPrefabs, payload);
};

export const getPrefabValidation = (uuid: string) => {
	return client.post<PrefabValidationResponse>(
		Endpoints.validatePrefab(uuid)
	);
};

export const getSalesforceAccountInfo = (uuid: string) => {
	return client.get<{ results: SalesforceAccountInfo }>(
		Endpoints.salesforceAccountInfo(uuid)
	);
};

export const getActiveTemplate = (lang: LanguageShort, id: string) => {
	return client.get<AssessmentTemplate>(Endpoints.getAssessment(id), {
		headers: {
			'Accept-Language': lang,
		},
	});
};

export const postPartialAssessmentResponse = (
	assessmentId: string,
	payload: AssessmentPartialPayload,
	lang: LanguageShort
) => {
	return client.patch<AssessmentUpdateResponse>(
		Endpoints.saveProgress(assessmentId),
		payload,
		{
			headers: {
				'Accept-Language': lang,
			},
		}
	);
};

export const getDetailedReport = (lang: LanguageShort, uuid: string) => {
	return client.get<ReportData>(Endpoints.detailedReport(uuid), {
		headers: {
			'Accept-Language': lang,
		},
	});
};

export const getBackendDeploymentDetails = () => {
	return client.get<BackendDeploymentDetails>(
		Endpoints.backendDeploymentDetails
	);
};
