// allows us to check urls at compile time for correct formatting
type ApiUrl = `/${string}/` | `/${string}/?${string}`;
type ApiUrls = Record<string, ApiUrl | ((...args: string[]) => ApiUrl)>;

const Endpoints = {
	// auth
	oidcLogin: '/oidc/login/', // no-creds
	oidcLogout: (slo: string): ApiUrl => `/oidc/logout/?slo=${slo}`, // creds
	oidcRefresh: '/oidc/refresh/', // creds

	// user
	user: '/api/v1/me/',

	// prefabs
	getPrefabs: (page: string, rowsPerPage: string): ApiUrl =>
		`/api/v2/prefabs/?page=${page}&page_size=${rowsPerPage}`,
	postPrefabs: '/api/v2/prefabs/',
	validatePrefab: (id: string): ApiUrl => `/api/v2/prefabs/${id}/validate/`,
	salesforceAccountInfo: (uuid: string): ApiUrl =>
		`/api/v1/lookup_account/${uuid}/`,

	// assessment
	// replaces activeTemplate
	getAssessment: (id: string): ApiUrl => `/api/v2/assessments/${id}/`,
	// replaces assessmentResponse
	saveProgress: (id: string): ApiUrl => `/api/v2/assessments/${id}/`,
	validatePartialAssessmentResponse: '/api/v1/assessments/validate/',

	// report
	detailedReport: (id: string): ApiUrl =>
		`/api/v1/assessments/${id}/detailed_report/`,

	// meta
	backendDeploymentDetails: `/api/v1/deployment-details/`,
} satisfies ApiUrls;

export default Endpoints;
